<template>
  <validation-provider
    :rules="rules"
    :name="`«${name}»`"
    v-slot="v"
    tag="div"
    :vid="vid"
    :mode="counter > 1 ? 'aggressive' : 'passive'"
    ref="provider"
  >
    <div
      :class="{
        'hm-form__element-wrapper': true,
        'hm-form__element-wrapper--error': v.errors.length
      }"
    >
      <div class="hm-form__datepicker">
        <esmp-datepicker-adaptive
          v-model="localValue"
          @change="onInput"
          :placeholder="(required ? '*' : '') + placeholder"
          clearable
          :is-multiple-mode="isMultipleMode"
          :is-range-mode="isRangeMode"
          :is-time-selectable="showTime"
          :time-interval="timeInterval"
          :disabled-dates="disabledDates"
        />
        <esmp-input
          ref="interval"
          class="hm-form__datepicker-interval"
          v-if="showTime"
          v-model.number="timeInterval"
          :options="{ type: 'number', min: 1, max: 60 }"
          @input="onIntervalInput"
          label="Интервал (в минутах)"
        />
      </div>
      <div v-if="v.errors.length || hintText" class="hm-form__element-hint">
        {{ v.errors.length ? v.errors[0] : hintText }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import uid from '@/components/hm-form/mixins/uid';

dayjs.extend(weekday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const MODE_SINGLE = 'single'; // Выбор одной даты
const MODE_RANGE = 'range'; // Выбор диапазона дат
const MODE_MULTIPLE = 'multiple'; // Выбор нескольких дат

const VIEW_MODES = Object.freeze([MODE_SINGLE, MODE_RANGE, MODE_MULTIPLE]);

export default {
  name: 'HmGraphCalendar',
  mixins: [uid],
  props: {
    value: {
      type: [Array, Date, Object],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    hintText: {
      type: String,
      default: undefined,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    excludeHolidays: {
      type: String,
      default: '',
    },
    excludeWeekends: {
      type: Boolean,
      default: false,
    },
    datesToShowType: {
      type: Number,
      default: 0,
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    minRelativeDate: {
      type: String,
      default: '',
    },
    maxRelativeDate: {
      type: String,
      default: '',
    },
    excludeMinRelativeDate: {
      type: String,
      default: '',
    },
    excludeMaxRelativeDate: {
      type: String,
      default: '',
    },
    viewMode: {
      type: String,
      validator(value) {
        return VIEW_MODES.includes(value);
      },
      default: MODE_SINGLE,
    },
  },
  computed: {
    isRangeMode() {
      return this.viewMode === MODE_RANGE;
    },
    isMultipleMode() {
      return this.viewMode === MODE_MULTIPLE;
    },
  },
  data() {
    return {
      localValue: this.value,
      counter: 0,
      timeInterval: 15,
    };
  },
  methods: {
    disabledDates(date) {
      const now = new Date();

      const isFutureAndToday = (d) => {
        if (this.datesToShowType === 2) {
          return !dayjs(d).isSameOrAfter(now, 'day');
        }
        return false;
      };
      const isPastAndToday = (d) => {
        if (this.datesToShowType === 1) {
          return dayjs(d).isAfter(now, 'day');
          // return dayjs(d).isSameOrAfter(now, 'day');
        }
        return false;
      };
      const isFuture = (d) => {
        if (this.datesToShowType === 6) {
          return dayjs(d).isBefore(dayjs(now));
        }
        return false;
      };
      const isPast = (d) => {
        if (this.datesToShowType === 5) {
          return dayjs(d).isAfter(dayjs(now).subtract(1, 'day'));
        }
        return false;
      };
      const isRange = (d) => {
        if (this.datesToShowType === 3) {
          return !dayjs(d).isBetween(this.minDate, this.maxDate, 'day', '[]');
        }
        return false;
      };
      const isRelativeRange = (d) => {
        if (!this.minRelativeDate || !this.maxRelativeDate) return false;

        if (this.datesToShowType === 4) {
          const minRelativeDateArray = this.minRelativeDate.split('-');
          const maxRelativeDateArray = this.maxRelativeDate.split('-');
          const current = dayjs();
          let start = current;
          start = start.subtract(+minRelativeDateArray[0], 'year');
          start = start.subtract(+minRelativeDateArray[1], 'month');
          start = start.subtract(+minRelativeDateArray[2] + 1, 'day');
          start = start.subtract(+minRelativeDateArray[3], 'hour');
          let end = current;
          end = end.add(+maxRelativeDateArray[0], 'year');
          end = end.add(+maxRelativeDateArray[1], 'month');
          end = end.add(+maxRelativeDateArray[2], 'day');
          end = end.add(+maxRelativeDateArray[3], 'hour');
          return !dayjs(d).isBetween(start, end, null, '[]');
        }
        return false;
      };
      const excludeWeekends = (d) => {
        if (this.excludeWeekends) {
          return dayjs(d).weekday() === 0 || dayjs(d).weekday() === 6;
        }
        return false;
      };
      const excludeMinRelativeDate = (d) => {
        if (!this.excludeMinRelativeDate) return false;

        if (this.datesToShowType === 4 || this.datesToShowType === 0 || this.datesToShowType === 6) {
          const minRelativeDateArray = this.excludeMinRelativeDate.split('-');
          let start = dayjs();
          start = start.subtract(minRelativeDateArray[1], 'month');
          if (this.excludeWeekends) {
            let iterator = Number(minRelativeDateArray[2]);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i <= iterator; i++) {
              start = start.subtract(1, 'day');
              if (excludeWeekends(start)) {
                // eslint-disable-next-line no-plusplus
                iterator++;
              }
            }
          } else {
            start = start.subtract(minRelativeDateArray[2], 'day');
          }
          start = start.subtract(minRelativeDateArray[3], 'hour');

          return dayjs(d).isBetween(start, dayjs().subtract(1, 'day'), 'hour', '[]');
        }
        return false;
      };
      const excludeMaxRelativeDate = (d) => {
        if (!this.excludeMaxRelativeDate) return false;

        if (this.datesToShowType === 4 || this.datesToShowType === 0 || this.datesToShowType === 6) {
          const maxRelativeDateArray = this.excludeMaxRelativeDate.split('-');
          let end = dayjs();
          end = end.add(maxRelativeDateArray[1], 'month');
          if (this.excludeWeekends) {
            let iterator = Number(maxRelativeDateArray[2]);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < iterator; i++) {
              end = end.add(1, 'day');
              if (excludeWeekends(end)) {
                // eslint-disable-next-line no-plusplus
                iterator++;
              }
            }
          } else {
            end = end.add(maxRelativeDateArray[2], 'day');
          }
          end = end.add(maxRelativeDateArray[3], 'hour');
          return dayjs(d).isBetween(dayjs(), end, null, '(]');
        }
        return false;
      };
      const excludeHolidays = (d) => {
        if (this.excludeHolidays) {
          return this.excludeHolidays.split(',').includes(dayjs(d).format('DD.MM.YYYY'));
        }
        return false;
      };

      // const results = {
      //   date,
      //   excludeWeekends: excludeWeekends(date),
      //   excludeHolidays: excludeHolidays(date),
      //   isFutureAndToday: isFutureAndToday(date),
      //   isPastAndToday: isPastAndToday(date),
      //   isFuture: isFuture(date),
      //   isPast: isPast(date),
      //   isRange: isRange(date),
      //   isRelativeRange: isRelativeRange(date),
      //   excludeMinRelativeDate: excludeMinRelativeDate(date),
      //   excludeMaxRelativeDate: excludeMaxRelativeDate(date),
      // };

      // if (String(date.getDate()).padStart(2, '0') === '18') {
      //   console.table(results);
      // }

      return excludeWeekends(date)
        || excludeHolidays(date)
        || isFutureAndToday(date) || isPastAndToday(date)
        || isFuture(date) || isPast(date)
        || isRange(date) || isRelativeRange(date)
        || excludeMinRelativeDate(date) || excludeMaxRelativeDate(date);
    },
    onInput(val) {
      this.counter += 1;
      this.$emit('input', val);
      if (this.counter > 1) {
        this.$forceNextTick(() => {
          this.$refs.provider.validate();
        });
      }
    },
    onIntervalInput(val) {
      if (+val > 60) {
        this.timeInterval = 60;
        this.$refs.interval.localValue = this.timeInterval;
      } else if (+val < 1) {
        this.timeInterval = 1;
        this.$refs.interval.localValue = this.timeInterval;
      }
    },
  },
};
</script>
